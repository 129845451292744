// File: src/components/ui/accordion.jsx
import * as React from "react"
import { ChevronDown } from "lucide-react"

const AccordionContext = React.createContext({
  openItems: [],
  toggle: () => {},
  type: 'single',
  collapsible: false
})

const Accordion = React.forwardRef(({ 
  children, 
  type = 'single', 
  collapsible = false,
  className = "",
  ...props 
}, ref) => {
  const [openItems, setOpenItems] = React.useState([])

  const toggle = (value) => {
    if (type === 'single') {
      setOpenItems(prevItems => {
        // If collapsible is enabled, allow closing the open item
        if (collapsible && prevItems.includes(value)) {
          return []
        }
        // Otherwise, always have one item open
        return [value]
      })
    } else {
      setOpenItems(
        openItems.includes(value)
          ? openItems.filter(item => item !== value)
          : [...openItems, value]
      )
    }
  }

  return (
    <AccordionContext.Provider value={{ openItems, toggle, type, collapsible }}>
      <div 
        ref={ref} 
        className={className}
        data-orientation="vertical"
        {...props}
      >
        {children}
      </div>
    </AccordionContext.Provider>
  )
})

Accordion.displayName = "Accordion"

const AccordionItem = React.forwardRef(({ className = "", children, value, ...props }, ref) => {
  const { openItems } = React.useContext(AccordionContext)
  const isOpen = openItems.includes(value)

  return (
    <div 
      ref={ref} 
      className={`border-b last:border-0 ${className}`}
      data-state={isOpen ? "open" : "closed"}
      {...props}
    >
      {React.Children.map(children, child =>
        React.isValidElement(child)
          ? React.cloneElement(child, { isOpen, value })
          : child
      )}
    </div>
  )
})

AccordionItem.displayName = "AccordionItem"

const AccordionTrigger = React.forwardRef(({ className = "", children, value, isOpen, ...props }, ref) => {
  const { toggle } = React.useContext(AccordionContext)

  return (
    <button
      ref={ref}
      onClick={() => toggle(value)}
      className={`flex w-full items-center justify-between py-4 text-left font-medium transition-all hover:underline [&[data-state=open]>svg]:rotate-180 ${className}`}
      {...props}
    >
      {children}
      <ChevronDown
        className={`h-4 w-4 shrink-0 text-muted-foreground transition-transform duration-200 ${
          isOpen ? "rotate-180" : ""
        }`}
      />
    </button>
  )
})

AccordionTrigger.displayName = "AccordionTrigger"

const AccordionContent = React.forwardRef(({ className = "", children, isOpen, ...props }, ref) => (
  <div
    ref={ref}
    className={`overflow-hidden transition-all ${isOpen ? "pb-4" : "h-0"} ${className}`}
    {...props}
  >
    {children}
  </div>
))

AccordionContent.displayName = "AccordionContent"

export { Accordion, AccordionItem, AccordionTrigger, AccordionContent }