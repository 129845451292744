// File: src/components/ui/SortMenu.jsx
// Description: Sort menu component with corrected import path
// Update: Fixed path to useClickOutside hook with relative path

import React from 'react';
import { ArrowUpDown, ChevronDown } from 'lucide-react';
import useClickOutside from '../../hooks/useClickOutside';

const SortMenu = ({ sortOption, setSortOption, sortOptions }) => {
  const [showMenu, setShowMenu] = React.useState(false);
  const menuRef = useClickOutside(() => setShowMenu(false));

  // Find current sort option label
  const currentSort = sortOptions.find(option => option.id === sortOption);

  return (
    <div className="relative" ref={menuRef}>
      <button
        onClick={() => setShowMenu(!showMenu)}
        className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        <ArrowUpDown className="h-4 w-4" />
        <span>{currentSort.label}</span>
        <ChevronDown className={`h-4 w-4 transform transition-transform ${showMenu ? 'rotate-180' : ''}`} />
      </button>

      {showMenu && (
        <div className="absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
          <div className="py-1" role="menu">
            {sortOptions.map((option) => {
              const Icon = option.icon;
              return (
                <button
                  key={option.id}
                  onClick={() => {
                    setSortOption(option.id);
                    setShowMenu(false);
                  }}
                  className={`
                    w-full text-left px-4 py-2 text-sm flex items-center gap-2
                    ${sortOption === option.id ? 'bg-blue-50 text-blue-700' : 'text-gray-700 hover:bg-gray-50'}
                  `}
                  role="menuitem"
                >
                  <Icon className="h-4 w-4" />
                  {option.label}
                </button>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default SortMenu;