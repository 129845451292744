// src/pages/Home/components/HeroSection.jsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowRight, Search, Globe, Shield } from 'lucide-react';

const HeroSection = () => {
  const navigate = useNavigate();

  return (
    <section className="relative py-20 bg-white">
      <div className="max-w-4xl mx-auto text-center px-4">
        <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
          Make informed choices about your
          <span className="block text-blue-600 mt-2">drinking water safety</span>
        </h1>
        <p className="mt-6 text-xl text-gray-600 mx-auto max-w-2xl">
          Access detailed water quality reports, understand health risks, and compare different water sources to protect your health.
        </p>

        <div className="mt-10 flex flex-col sm:flex-row justify-center gap-4">
          <button
            onClick={() => navigate('/directory')}
            className="inline-flex items-center px-8 py-4 text-lg font-medium text-white bg-blue-600 hover:bg-blue-700 rounded-lg shadow-sm transition-colors"
          >
            Compare Water Sources
            <ArrowRight className="ml-2 h-5 w-5" />
          </button>
          <button
            onClick={() => navigate('/tap-water')}
            className="inline-flex items-center px-8 py-4 text-lg font-medium text-blue-600 bg-blue-50 hover:bg-blue-100 rounded-lg shadow-sm transition-colors"
          >
            Check Your Tap Water
            <Search className="ml-2 h-5 w-5" />
          </button>
        </div>

        <div className="mt-12 grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="flex items-center justify-center gap-3">
            <Globe className="h-6 w-6 text-blue-600" />
            <span className="text-gray-600">100+ water brands</span>
          </div>
          <div className="flex items-center justify-center gap-3">
            <Shield className="h-6 w-6 text-blue-600" />
            <span className="text-gray-600">Lab-verified data</span>
          </div>
          <div className="flex items-center justify-center gap-3">
            <Search className="h-6 w-6 text-blue-600" />
            <span className="text-gray-600">Latest test results</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;