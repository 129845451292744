// File: src/pages/ProductDetail/components/SafeLevelsSection.jsx
import React from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { Check } from 'lucide-react';
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from '@/components/ui/accordion';
import ContaminantDetails from './ContaminantDetails';

const SafeLevelsSection = ({ classifiedContaminants }) => {
  if (!classifiedContaminants.safe?.length) {
    return null;
  }

  return (
    <Card className="mb-6 bg-green-50 border-green-200">
      <CardHeader>
        <CardTitle className="text-lg flex items-center text-green-700">
          <Check className="h-5 w-5 mr-2" />
          Safe Levels
        </CardTitle>
      </CardHeader>
      <CardContent>
        <Accordion type="multiple" className="space-y-2">
          {classifiedContaminants.safe.map((substance, index) => (
            <AccordionItem 
              key={`${substance.name}-${index}`} 
              value={`safe-${index}`} 
              className="border-green-200"
            >
              <AccordionTrigger className="text-green-700">
                {substance.name}
              </AccordionTrigger>
              <AccordionContent>
                <ContaminantDetails contaminant={substance} />
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      </CardContent>
    </Card>
  );
};

export default SafeLevelsSection;