// File: src/pages/ProductDetail/components/AdditionalInfo.jsx
import React from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { Info } from 'lucide-react';
import PHMeter from '../../../components/PHMeter';

const AdditionalInfo = ({ waterProduct }) => {
  return (
    <Card className="mb-6">
      <CardHeader>
        <CardTitle className="text-lg flex items-center">
          <Info className="h-5 w-5 mr-2" />
          Additional Information
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          {waterProduct.ph && (
            <div>
              <div className="flex items-center">
                <span className="font-medium">pH Level:</span>
                <span className="ml-2 text-gray-600">{waterProduct.ph}</span>
              </div>
              <PHMeter ph={waterProduct.ph} />
            </div>
          )}

          {waterProduct.tds && (
            <div>
              <span className="font-medium">Total Dissolved Solids:</span>
              <span className="ml-2 text-gray-600">{waterProduct.tds} mg/L</span>
            </div>
          )}

          {waterProduct.source && (
            <div>
              <span className="font-medium">Source:</span>
              <span className="ml-2 text-gray-600">{waterProduct.source}</span>
            </div>
          )}

          {waterProduct.treatment_process && (
            <div>
              <span className="font-medium">Treatment Process:</span>
              <span className="ml-2 text-gray-600">{waterProduct.treatment_process}</span>
            </div>
          )}

          {waterProduct.packaging && (
            <div>
              <span className="font-medium">Packaging:</span>
              <span className="ml-2 text-gray-600">{waterProduct.packaging}</span>
            </div>
          )}

          {waterProduct.classifiedContaminants?.safe?.length > 0 && (
            <div>
              <span className="font-medium">Safe-Level Substances:</span>
              <div className="mt-2 space-y-1">
                {waterProduct.classifiedContaminants.safe.map((cont, idx) => (
                  <div key={idx} className="text-sm text-gray-600">
                    {cont.name}: {cont.amount} {cont.unit?.name || ''}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default AdditionalInfo;