// File: src/pages/TapWater/TapWaterDirectory.jsx
import React, { useEffect, useCallback, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AlertTriangle, Droplets, ChevronDown } from 'lucide-react';
import { useTapWater } from '../../contexts/TapWaterContext';

const DEBUG = true;
const log = (...args) => DEBUG && console.log('[TapWaterDirectory]', ...args);

const TapWaterDirectory = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { 
    states, 
    loading, 
    navigationState,
    updateNavigationState 
  } = useTapWater();

  // Local state for accordion
  const [expandedState, setExpandedState] = useState(navigationState.selectedState || null);

  // Handle scroll position restoration
  useEffect(() => {
    if (location.state?.scrollPosition) {
      requestAnimationFrame(() => {
        window.scrollTo(0, location.state.scrollPosition);
      });
    }
  }, [location.state]);

  // Sync accordion state with navigation state
  useEffect(() => {
    if (navigationState.selectedState) {
      setExpandedState(navigationState.selectedState);
    }
  }, [navigationState.selectedState]);

  const handleMunicipalityClick = useCallback((municipality, stateName) => {
    const currentPosition = window.scrollY;
    log('Municipality clicked:', { municipality: municipality.name, state: stateName, position: currentPosition });

    updateNavigationState({
      selectedState: stateName,
      scrollPosition: currentPosition
    });

    navigate(`/tap-water/${municipality.id}`, {
      state: { 
        from: 'directory', 
        selectedState: stateName,
        scrollPosition: currentPosition 
      }
    });
  }, [navigate, updateNavigationState]);

  const handleStateClick = useCallback((stateName) => {
    log('State clicked:', stateName);
    setExpandedState(expandedState === stateName ? null : stateName);
    updateNavigationState({ selectedState: stateName });
  }, [expandedState, updateNavigationState]);

  const getViolationCount = useCallback((municipality) => {
    return municipality.contaminants.filter(c => {
      const level = parseFloat(c.level);
      const guideline = parseFloat(c.health_guideline);
      return !isNaN(level) && !isNaN(guideline) && level > guideline;
    }).length;
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[200px]">
        <div className="text-center">
          <div className="w-16 h-16 border-4 border-blue-500 border-t-transparent rounded-full animate-spin mx-auto mb-4"></div>
          <div className="text-gray-600">Loading tap water data...</div>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="mb-4">
        <p className="text-gray-600 text-sm">
          Select your state to view water quality reports from local municipalities.
        </p>
      </div>

      <div className="space-y-4">
        {Object.values(states).map((state) => (
          <div 
            key={state.name} 
            className="rounded-lg border bg-white overflow-hidden"
          >
            <button
              onClick={() => handleStateClick(state.name)}
              className="w-full px-4 py-3 flex items-center justify-between hover:bg-gray-50"
            >
              <div className="flex items-center gap-3">
                <div className="p-2 rounded-lg bg-blue-50">
                  <Droplets className="h-5 w-5 text-blue-500" />
                </div>
                <div className="text-left">
                  <h3 className="font-semibold text-gray-900">{state.name}</h3>
                  <p className="text-sm text-gray-500">
                    {state.municipalities.length} water systems
                  </p>
                </div>
              </div>
              <ChevronDown 
                className={`h-5 w-5 text-gray-500 transition-transform duration-200 ${
                  expandedState === state.name ? 'transform rotate-180' : ''
                }`}
              />
            </button>

            {expandedState === state.name && (
              <div className="border-t">
                <div className="px-4 py-4 space-y-3">
                  {state.municipalities.map((municipality) => {
                    const violationCount = getViolationCount(municipality);

                    return (
                      <div
                        key={municipality.id}
                        onClick={() => handleMunicipalityClick(municipality, state.name)}
                        className="p-4 border rounded-lg hover:shadow-md transition-shadow cursor-pointer"
                      >
                        <div className="flex justify-between items-start">
                          <div>
                            <h4 className="font-medium text-gray-900">{municipality.name}</h4>
                            <p className="text-sm text-gray-600 mt-1">
                              {municipality.contaminants.length} detected contaminants
                            </p>
                          </div>
                          {violationCount > 0 && (
                            <div className="flex items-center gap-1 text-amber-600">
                              <AlertTriangle className="h-4 w-4" />
                              <span className="text-sm font-medium">
                                {violationCount} above guidelines
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TapWaterDirectory;