// File: src/pages/Home/index.jsx
import React from 'react';
import HeroSection from './components/HeroSection';
import IssuesSection from './components/IssuesSection';
import FeaturesSection from './components/FeaturesSection';
import CTASection from './components/CTASection';

const Home = () => {
  return (
    <div className="min-h-screen">
      <HeroSection />
      <IssuesSection />
      <FeaturesSection />
      <CTASection />
    </div>
  );
};

export default Home;