// File: src/pages/ProductDetail/components/ConcernsSection.jsx
import React from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { AlertCircle } from 'lucide-react';
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from '@/components/ui/accordion';
import ContaminantDetails from './ContaminantDetails';

const ConcernsSection = ({ classifiedContaminants }) => {
  if (!classifiedContaminants.concern?.length) {
    return null;
  }

  return (
    <Card className="mb-6 bg-yellow-50 border-yellow-200">
      <CardHeader>
        <CardTitle className="text-lg flex items-center text-yellow-700">
          <AlertCircle className="h-5 w-5 mr-2" />
          Health Concerns
        </CardTitle>
      </CardHeader>
      <CardContent>
        <Accordion type="multiple" className="space-y-2">
          {classifiedContaminants.concern.map((contaminant, index) => (
            <AccordionItem 
              key={`${contaminant.name}-${index}`} 
              value={`concern-${index}`} 
              className="border-yellow-200"
            >
              <AccordionTrigger className="text-yellow-700">
                {contaminant.name}
              </AccordionTrigger>
              <AccordionContent>
                <ContaminantDetails contaminant={contaminant} />
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      </CardContent>
    </Card>
  );
};

export default ConcernsSection;