//File: src/contexts/WaterDataContext.jsx
import React, { createContext, useContext, useState, useEffect } from 'react';
import { supabase } from '@/lib/supabase';

const WaterDataContext = createContext();

export function WaterDataProvider({ children }) {
  const [products, setProducts] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      // Check localStorage first
      const cachedData = localStorage.getItem('waterData');
      const cachedTimestamp = localStorage.getItem('waterDataTimestamp');

      // If we have cached data and it's less than 24 hours old, use it
      if (cachedData && cachedTimestamp) {
        const age = Date.now() - parseInt(cachedTimestamp);
        if (age < 24 * 60 * 60 * 1000) { // 24 hours
          setProducts(JSON.parse(cachedData));
          setLoading(false);
          return;
        }
      }

      // Otherwise fetch fresh data
      const { data, error } = await supabase
        .from('water_products')
        .select(`
          *,
          water_product_contaminants (
            amount,
            measured_at,
            contaminants (
              name,
              legal_limit,
              health_guideline,
              description,
              risks,
              contaminant_categories (
                name,
                severity,
                description
              )
            )
          )
        `);

      if (error) {
        console.error('Error fetching water products:', error);
        return;
      }

      const transformedData = data.map(product => ({
        ...product,
        contaminants: (product.water_product_contaminants || [])
          .filter(wpc => wpc && wpc.contaminants)
          .map(wpc => ({
            name: wpc.contaminants.name,
            amount: wpc.amount,
            legal_limit: wpc.contaminants.legal_limit,
            health_guideline: wpc.contaminants.health_guideline,
            description: wpc.contaminants.description,
            risks: wpc.contaminants.risks,
            category: wpc.contaminants.contaminant_categories,
            measured_at: wpc.measured_at
          }))
      }));

      // Cache the data
      localStorage.setItem('waterData', JSON.stringify(transformedData));
      localStorage.setItem('waterDataTimestamp', Date.now().toString());

      setProducts(transformedData);
      setLoading(false);
    };

    loadData();
  }, []);

  return (
    <WaterDataContext.Provider value={{ products, loading }}>
      {children}
    </WaterDataContext.Provider>
  );
}

export function useWaterData() {
  return useContext(WaterDataContext);
}