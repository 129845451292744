// File: src/pages/ProductDetail/components/ContaminantDetails.jsx
import React from 'react';
import { Progress } from '@/components/ui/progress';

export const ContaminantDetails = ({ contaminant }) => {
  const percentOfHealthGuideline = contaminant.amount / contaminant.health_guideline * 100;

  return (
    <div className="p-4 rounded-lg bg-opacity-50" style={{
      backgroundColor: percentOfHealthGuideline > 100 
        ? 'rgb(254, 226, 226)' 
        : percentOfHealthGuideline > 75 
          ? 'rgb(254, 243, 199)'
          : 'rgb(220, 252, 231)'
    }}>
      <div className="font-medium">Current Levels:</div>
      <p className="mt-1">
        {contaminant.amount} {contaminant.unit?.name || ''}
      </p>

      {contaminant.health_guideline && (
        <>
          <div className="font-medium mt-3">Health Guideline:</div>
          <p className="mt-1">{contaminant.health_guideline} {contaminant.unit?.name || ''}</p>
          <div className="mt-2">
            <Progress 
              value={Math.min(percentOfHealthGuideline, 100)}
              className={`h-2 ${
                percentOfHealthGuideline > 100 ? 'bg-red-200' :
                percentOfHealthGuideline > 75 ? 'bg-yellow-200' :
                'bg-green-200'
              }`}
            />
            <div className="text-sm mt-1 text-right">
              {Math.round(percentOfHealthGuideline)}% of health guideline
            </div>
          </div>
        </>
      )}

      {contaminant.risks && (
        <>
          <div className="font-medium mt-3">Health Risks:</div>
          <p className="mt-1 text-sm">{contaminant.risks}</p>
        </>
      )}

      {contaminant.description && (
        <>
          <div className="font-medium mt-3">Additional Information:</div>
          <p className="mt-1 text-sm">{contaminant.description}</p>
        </>
      )}

      {contaminant.measured_at && (
        <div className="text-xs text-gray-500 mt-3">
          Last measured: {new Date(contaminant.measured_at).toLocaleDateString()}
        </div>
      )}
    </div>
  );
};

export default ContaminantDetails;