// File: src/contexts/TapWaterContext.jsx
import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { supabase } from '@/lib/supabase';

const TapWaterContext = createContext();

const DEBUG = true;
const log = (...args) => DEBUG && console.log('[TapWaterContext]', ...args);

export function TapWaterProvider({ children }) {
  const [data, setData] = useState({ states: {}, municipalities: {} });
  const [loading, setLoading] = useState(true);
  const [navigationState, setNavigationState] = useState({
    selectedState: null,
    scrollPosition: 0
  });

  useEffect(() => {
    const fetchData = async () => {
      log('Fetching tap water data');

      try {
        const { data: statesData } = await supabase
          .from('states')
          .select('*')
          .order('name');

        const statesWithMunicipalities = {};
        const allMunicipalities = {};

        for (const state of statesData) {
          const { data: muniData } = await supabase
            .from('municipalities')
            .select(`
              id,
              name,
              state_id,
              municipal_water_contaminants (
                level,
                unit,
                contaminants (
                  name,
                  legal_limit,
                  health_guideline,
                  description,
                  risks
                )
              )
            `)
            .eq('state_id', state.id)
            .order('name');

          const processedMunicipalities = muniData
            .filter(Boolean)
            .map(municipality => ({
              id: municipality.id,
              name: municipality.name,
              state: state.name,
              state_id: state.id,
              contaminants: municipality.municipal_water_contaminants
                ?.filter(Boolean)
                .map(c => ({
                  name: c.contaminants?.name,
                  level: c.level,
                  unit: c.unit,
                  legal_limit: c.contaminants?.legal_limit,
                  health_guideline: c.contaminants?.health_guideline,
                  description: c.contaminants?.description,
                  risks: c.contaminants?.risks
                })) || []
            }))
            .filter(m => m.contaminants.length > 0);

          if (processedMunicipalities.length > 0) {
            statesWithMunicipalities[state.name] = {
              name: state.name,
              id: state.id,
              municipalities: processedMunicipalities
            };

            processedMunicipalities.forEach(muni => {
              allMunicipalities[muni.id] = muni;
            });
          }
        }

        setData({
          states: statesWithMunicipalities,
          municipalities: allMunicipalities
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []); // Only fetch once when component mounts

  const updateNavigationState = useCallback((newState) => {
    log('Updating navigation state:', newState);
    setNavigationState(current => {
      // Save scroll position if not provided
      if (!newState.scrollPosition && newState.selectedState) {
        newState.scrollPosition = window.scrollY;
      }
      return { ...current, ...newState };
    });
  }, []);

  const getMunicipality = useCallback((id) => {
    return data.municipalities[id] || null;
  }, [data.municipalities]);

  const value = {
    states: data.states,
    loading,
    getMunicipality,
    navigationState,
    updateNavigationState
  };

  return (
    <TapWaterContext.Provider value={value}>
      {children}
    </TapWaterContext.Provider>
  );
}

export function useTapWater() {
  const context = useContext(TapWaterContext);
  if (!context) {
    throw new Error('useTapWater must be used within a TapWaterProvider');
  }
  return context;
}