// File: src/pages/ProductDetail/ProductDetailPage.jsx
import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import ProductDetailView from './ProductDetailView';
import { supabase } from '@/lib/supabase';

const ProductDetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);

  // Scroll to top on mount
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const { data, error } = await supabase
          .from('water_products')
          .select(`
            *,
            water_product_contaminants!left (
              amount,
              measured_at,
              contaminants!left (
                name,
                measurement_units!left (
                  name,
                  description
                ),
                health_guideline,
                description,
                risks,
                contaminant_categories!left (
                  name,
                  severity,
                  description
                )
              )
            )
          `)
          .eq('id', id)
          .single();

        if (error) throw error;

        const transformedProduct = {
          ...data,
          contaminants: data.water_product_contaminants
            .filter(wpc => wpc && wpc.contaminants)
            .map(wpc => ({
              name: wpc.contaminants.name,
              amount: wpc.amount,
              unit: wpc.contaminants.measurement_units,
              health_guideline: wpc.contaminants.health_guideline,
              description: wpc.contaminants.description,
              risks: wpc.contaminants.risks,
              category: wpc.contaminants.contaminant_categories,
              measured_at: wpc.measured_at,
              percentOfHealthGuideline: wpc.contaminants.health_guideline 
                ? (wpc.amount / wpc.contaminants.health_guideline) * 100 
                : null
            }))
            .sort((a, b) => {
              const percentA = a.percentOfHealthGuideline || 0;
              const percentB = b.percentOfHealthGuideline || 0;
              return percentB - percentA;
            })
        };

        const classifiedContaminants = transformedProduct.contaminants.reduce((acc, cont) => {
          if (cont.name.toLowerCase().includes('pfas')) {
            return acc;
          }

          let status = 'safe';

          if (cont.health_guideline && cont.amount > cont.health_guideline) {
            status = 'warning';
          }
          else if (cont.health_guideline && cont.amount > (cont.health_guideline * 0.75)) {
            status = 'concern';
          }

          if (!acc[status]) {
            acc[status] = [];
          }
          acc[status].push(cont);

          return acc;
        }, {});

        setProduct({ ...transformedProduct, classifiedContaminants });
      } catch (error) {
        console.error('Error fetching product:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [id]);

  const handleBack = () => {
    // If we have location state, use it to navigate back
    if (location.state?.from) {
      navigate(location.state.from.pathname, {
        state: {
          scrollPosition: location.state.scrollPosition,
          selectedCategory: location.state.selectedCategory,
          viewType: location.state.viewType
        }
      });
    } else {
      // Default fallback
      navigate('/directory');
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-center">
          <div className="w-16 h-16 border-4 border-blue-500 border-t-transparent rounded-full animate-spin mx-auto mb-4"></div>
          <div className="text-gray-600">Loading product details...</div>
        </div>
      </div>
    );
  }

  if (!product) {
    return (
      <div className="max-w-3xl mx-auto p-6">
        <div className="text-center py-12">
          <h2 className="text-2xl font-semibold text-gray-900 mb-2">Product Not Found</h2>
          <p className="text-gray-600 mb-6">The water product you're looking for doesn't exist.</p>
          <button
            onClick={handleBack}
            className="text-blue-600 hover:text-blue-800 font-medium"
          >
            Return to Directory
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-gray-50 min-h-screen pb-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="py-6">
          <button
            onClick={handleBack}
            className="flex items-center text-gray-600 hover:text-gray-900"
          >
            <ArrowLeft className="h-4 w-4 mr-2" />
            Back to Directory
          </button>
        </div>
        <ProductDetailView waterProduct={product} />
      </div>
    </div>
  );
};

export default ProductDetailPage;