// File: src/pages/ProductDetail/components/WarningsSection.jsx
import React from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { AlertTriangle } from 'lucide-react';
import { 
  Accordion, 
  AccordionItem, 
  AccordionTrigger, 
  AccordionContent 
} from '@/components/ui/accordion';
import ContaminantDetails from './ContaminantDetails';

// Changed to regular export
export const WarningsSection = ({ classifiedContaminants, pfas, packaging }) => {
  // Filter out duplicate nitrate measurements
  const uniqueContaminants = classifiedContaminants?.warning?.filter(contaminant => {
    if (contaminant.name === 'Nitrate and Nitrite (Combined)') {
      return !classifiedContaminants.warning.some(c => 
        c.name === 'Nitrate' || c.name === 'Nitrite'
      );
    }
    return true;
  }) || [];

  if (!uniqueContaminants.length && pfas !== 'YES' && packaging?.toUpperCase() !== 'PLASTIC') {
    return null;
  }

  return (
    <Card className="mb-6 bg-red-50 border-red-200">
      <CardHeader>
        <CardTitle className="text-lg flex items-center text-red-700">
          <AlertTriangle className="h-5 w-5 mr-2" />
          Health Warnings
        </CardTitle>
      </CardHeader>
      <CardContent>
        <Accordion type="multiple" className="space-y-2">
          {pfas === 'YES' && (
            <AccordionItem value="pfas" className="border-red-200">
              <AccordionTrigger className="text-red-700">
                Forever Chemicals (PFAS) Detected
              </AccordionTrigger>
              <AccordionContent>
                <div className="p-4 bg-red-100/50 rounded-lg text-red-700">
                  <p>PFAS have been detected in this product.</p>
                  <p className="mt-2">These synthetic chemicals are persistent in the environment and human body, potentially leading to:</p>
                  <ul className="list-disc ml-6 mt-2 space-y-1">
                    <li>Increased cancer risk</li>
                    <li>Liver damage</li>
                    <li>Decreased fertility</li>
                    <li>Developmental delays in children</li>
                  </ul>
                </div>
              </AccordionContent>
            </AccordionItem>
          )}

          {packaging?.toUpperCase() === 'PLASTIC' && (
            <AccordionItem value="plastic" className="border-red-200">
              <AccordionTrigger className="text-red-700">
                Contains Microplastic Chemicals
              </AccordionTrigger>
              <AccordionContent>
                <div className="p-4 bg-red-100/50 rounded-lg text-red-700">
                  <p>This product is packaged in plastic, which can leach harmful chemicals into the water.</p>
                  <p className="mt-2">Key concerns include:</p>
                  <ul className="list-disc ml-6 mt-2 space-y-1">
                    <li>Phthalates leaching from the plastic container</li>
                    <li>BPA and BPA alternatives that can disrupt hormonal systems</li>
                    <li>Antimony leaching, particularly in warmer storage conditions</li>
                    <li>Microplastic particles that can carry additional toxic chemicals</li>
                  </ul>
                </div>
              </AccordionContent>
            </AccordionItem>
          )}

          {uniqueContaminants.length > 0 && (
            <AccordionItem value="contaminants" className="border-red-200">
              <AccordionTrigger className="text-red-700">
                {uniqueContaminants.length} Contaminant{uniqueContaminants.length !== 1 ? 's' : ''} Exceed{uniqueContaminants.length === 1 ? 's' : ''} Health Guidelines
              </AccordionTrigger>
              <AccordionContent>
                <div className="space-y-4">
                  <Accordion type="multiple" className="space-y-2">
                    {uniqueContaminants.map((contaminant, index) => (
                      <AccordionItem 
                        key={`${contaminant.name}-${index}`} 
                        value={`contaminant-${index}`} 
                        className="border-red-200"
                      >
                        <AccordionTrigger className="text-red-700 pl-4">
                          {contaminant.name}
                        </AccordionTrigger>
                        <AccordionContent>
                          <ContaminantDetails contaminant={contaminant} />
                        </AccordionContent>
                      </AccordionItem>
                    ))}
                  </Accordion>
                </div>
              </AccordionContent>
            </AccordionItem>
          )}
        </Accordion>
      </CardContent>
    </Card>
  );
};

// Add named export and default export
export default WarningsSection;