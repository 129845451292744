// File: src/App.jsx
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home';
import Directory from './pages/Directory';
import ProductDetailPage from './pages/ProductDetail/ProductDetailPage';
import TapWaterDirectory from './pages/TapWater/TapWaterDirectory';
import TapWaterDetail from './pages/TapWater/TapWaterDetail';
import { WaterDataProvider } from './contexts/WaterDataContext';
import { TapWaterProvider } from './contexts/TapWaterContext';

const App = () => {
  return (
    <WaterDataProvider>
      <TapWaterProvider>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="directory" element={<Directory />} />
            <Route path="product/:id" element={<ProductDetailPage />} />
            <Route path="tap-water" element={<TapWaterDirectory />} />
            <Route path="tap-water/:municipalityId" element={<TapWaterDetail />} />
          </Route>
        </Routes>
      </TapWaterProvider>
    </WaterDataProvider>
  );
};

export default App;