// File: src/components/PHMeter/index.jsx
// Description: New component to be created - Add this as a new file in the components directory
// Dependencies: Requires Tailwind CSS for styling

import React from 'react';

const PHMeter = ({ ph }) => {
  // pH ranges based on waterScoring.js
  const ranges = {
    optimal: { min: 6.5, max: 8.5 },
    acceptable: { min: 6.0, max: 9.0 }
  };

  // Calculate position of the pH indicator (0-100%)
  const position = ((ph - 0) / (14 - 0)) * 100;

  // Determine color based on pH value
  const getColor = (value) => {
    if (value >= ranges.optimal.min && value <= ranges.optimal.max) {
      return 'bg-green-500';
    } else if (value >= ranges.acceptable.min && value <= ranges.acceptable.max) {
      return 'bg-yellow-500';
    }
    return 'bg-red-500';
  };

  return (
    <div className="mt-2">
      <div className="relative h-8 bg-gray-200 rounded-lg overflow-hidden">
        {/* Optimal Range */}
        <div 
          className="absolute h-full bg-green-200 opacity-30"
          style={{
            left: `${(ranges.optimal.min / 14) * 100}%`,
            width: `${((ranges.optimal.max - ranges.optimal.min) / 14) * 100}%`
          }}
        />

        {/* Acceptable Range */}
        <div 
          className="absolute h-full bg-yellow-200 opacity-30"
          style={{
            left: `${(ranges.acceptable.min / 14) * 100}%`,
            width: `${((ranges.acceptable.max - ranges.acceptable.min) / 14) * 100}%`
          }}
        />

        {/* pH Indicator */}
        <div
          className={`absolute w-2 h-full ${getColor(ph)} transform -translate-x-1/2`}
          style={{ left: `${position}%` }}
        />
      </div>

      {/* Scale Labels */}
      <div className="flex justify-between mt-1 text-xs text-gray-600">
        <span>0</span>
        <span>Acidic</span>
        <span>7</span>
        <span>Basic</span>
        <span>14</span>
      </div>

      {/* Range Labels */}
      <div className="flex justify-center gap-4 mt-2 text-xs">
        <span className="text-green-600">Optimal: {ranges.optimal.min}-{ranges.optimal.max}</span>
        <span className="text-yellow-600">Acceptable: {ranges.acceptable.min}-{ranges.acceptable.max}</span>
      </div>
    </div>
  );
};

export default PHMeter;