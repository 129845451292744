//File: src/pages/TapWater/components/TapWaterSafeLevels.jsx
import React from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { Check } from 'lucide-react';
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from '@/components/ui/accordion';
import ContaminantDetails from './ContaminantDetails';

const TapWaterSafeLevels = ({ municipal_water_contaminants }) => {
  const safeContaminants = municipal_water_contaminants?.filter(cont => {
    if (!cont.contaminants?.health_guideline) return true;
    const level = parseFloat(cont.level);
    const guideline = parseFloat(cont.contaminants.health_guideline);
    return !isNaN(level) && !isNaN(guideline) && level <= guideline;
  }) || [];

  if (!safeContaminants.length) {
    return null;
  }

  return (
    <Card className="mb-6 bg-green-50 border-green-200">
      <CardHeader>
        <CardTitle className="text-lg flex items-center text-green-700">
          <Check className="h-5 w-5 mr-2" />
          Safe Levels
        </CardTitle>
      </CardHeader>
      <CardContent>
        <Accordion type="multiple" className="space-y-2">
          {safeContaminants.map((contaminant, index) => (
            <AccordionItem 
              key={`${contaminant.contaminants?.name}-${index}`} 
              value={`safe-${index}`}
              className="border-green-200"
            >
              <AccordionTrigger className="text-green-700">
                {contaminant.contaminants?.name}
              </AccordionTrigger>
              <AccordionContent>
                <ContaminantDetails contaminant={contaminant} />
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      </CardContent>
    </Card>
  );
};

export default TapWaterSafeLevels;