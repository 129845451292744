// File: src/pages/ProductDetail/components/ProductHeader.jsx
import React from 'react';
import { Info, X } from 'lucide-react';
import { calculateWaterScore, getGradeColor } from '../../../utils/waterScoring';

const ProductHeader = ({ waterProduct }) => {
  const [showScoreExplanation, setShowScoreExplanation] = React.useState(false);
  const { totalScore, letterGrade, penalties } = calculateWaterScore(waterProduct);
  const gradeColorClass = getGradeColor(letterGrade);

  // Filter and transform penalties for display
  const relevantPenalties = penalties
    .filter(p => !p.includes('bonus'))
    .map(penalty => {
      const mainPart = penalty.split(':')[0];
      return mainPart;
    });

  return (
    <>
      <div className="mb-6 flex justify-between items-start">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">{waterProduct.name}</h1>
          <p className="text-gray-600">{waterProduct.company}</p>
        </div>
        <div className="text-right flex items-center">
          <div className={`text-4xl font-bold ${gradeColorClass}`}>
            {letterGrade}
          </div>
          <Info 
            className="h-4 w-4 ml-2 text-gray-400 hover:text-gray-600 transition-colors cursor-pointer" 
            onClick={() => setShowScoreExplanation(true)}
          />
        </div>
      </div>

      {showScoreExplanation && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg max-w-md w-full max-h-[90vh] overflow-y-auto relative">
            <button 
              onClick={() => setShowScoreExplanation(false)}
              className="absolute right-4 top-4 text-gray-500 hover:text-gray-700"
            >
              <X className="h-4 w-4" />
            </button>

            <div className="p-6 space-y-4">
              <div className="text-lg font-semibold">Understanding This Score</div>

              <div className="bg-blue-50 border border-blue-200 rounded-lg p-4">
                <div className="text-blue-800 font-semibold">Score: {totalScore}/100</div>
                <div className="text-blue-700 text-sm">
                  Products start at 100 points and lose points for various concerns
                </div>
              </div>

              {relevantPenalties.length > 0 && (
                <div className="bg-red-50 border border-red-200 rounded-lg p-4">
                  <div className="text-red-800 font-semibold">Points were deducted for:</div>
                  <ul className="space-y-1 mt-2">
                    {relevantPenalties.map((penalty, index) => (
                      <li key={index} className="text-red-700 flex items-center">
                        <span className="h-1.5 w-1.5 rounded-full bg-red-500 mr-2"></span>
                        {penalty}
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              <div className="border rounded-lg p-4">
                <div className="font-semibold">Grading Scale</div>
                <div className="grid grid-cols-2 gap-2 mt-2">
                  <div className="text-green-700">A: 90-100</div>
                  <div className="text-emerald-700">B: 80-89</div>
                  <div className="text-yellow-700">C: 70-79</div>
                  <div className="text-orange-700">D: 60-69</div>
                  <div className="text-red-700">F: Below 60</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProductHeader;