// File: src/pages/ProductDetail/ProductDetailView.jsx
import React from 'react';
import ProductHeader from './components/ProductHeader';
import WarningsSection from './components/WarningsSection';
import ConcernsSection from './components/ConcernsSection';
import SafeLevelsSection from './components/SafeLevelsSection';
import AdditionalInfo from './components/AdditionalInfo';

const ProductDetailView = ({ waterProduct }) => {
  return (
    <div className="max-w-3xl mx-auto p-6">
      <ProductHeader waterProduct={waterProduct} />

      <WarningsSection 
        classifiedContaminants={waterProduct.classifiedContaminants}
        pfas={waterProduct.pfas}
        packaging={waterProduct.packaging}
      />

      <ConcernsSection 
        classifiedContaminants={waterProduct.classifiedContaminants}
      />

      <SafeLevelsSection 
        classifiedContaminants={waterProduct.classifiedContaminants}
      />

      <AdditionalInfo waterProduct={waterProduct} />
    </div>
  );
};

export default ProductDetailView;