// File: src/pages/Directory/index.jsx
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { GlassWater, Droplets, Sparkles, SortAsc, SortDesc } from 'lucide-react';
import { calculateWaterScore, getGradeColor } from '../../utils/waterScoring';
import { supabase } from '@/lib/supabase';
import SortMenu from '@/components/ui/SortMenu';
import TapWaterDirectory from '../TapWater/TapWaterDirectory';

const DEBUG = true;
const log = (...args) => DEBUG && console.log('[Directory]', ...args);

const Directory = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortOption, setSortOption] = useState('score-desc');

  const location = useLocation();

  useEffect(() => {
    const fetchProducts = async () => {
      const { data, error } = await supabase
        .from('water_products')
        .select(`
          *,
          water_product_contaminants (
            amount,
            measured_at,
            contaminants (
              name,
              legal_limit,
              health_guideline,
              description,
              risks,
              contaminant_categories (
                name,
                severity,
                description
              )
            )
          )
        `);

      if (error) {
        console.error('Error fetching water products:', error);
        return;
      }

      const transformedData = data.map(product => ({
        ...product,
        contaminants: (product.water_product_contaminants || [])
          .filter(wpc => wpc && wpc.contaminants)
          .map(wpc => ({
            name: wpc.contaminants.name,
            amount: wpc.amount,
            legal_limit: wpc.contaminants.legal_limit,
            health_guideline: wpc.contaminants.health_guideline,
            description: wpc.contaminants.description,
            risks: wpc.contaminants.risks,
            category: wpc.contaminants.contaminant_categories,
            measured_at: wpc.measured_at,
            percentOfLimit: wpc.contaminants.legal_limit 
              ? (wpc.amount / wpc.contaminants.legal_limit) * 100 
              : null,
            percentOfHealthGuideline: wpc.contaminants.health_guideline 
              ? (wpc.amount / wpc.contaminants.health_guideline) * 100 
              : null
          }))
      }));

      setProducts(transformedData);
      setLoading(false);
    };

    fetchProducts();
  }, []); // Only fetch once when component mounts

  // Handle returning from tap water detail
  useEffect(() => {
    if (location.state?.showTapWater) {
      setSelectedCategory('TAP');

      // Let the DOM update before scrolling
      if (location.state?.scrollPosition) {
        requestAnimationFrame(() => {
          window.scrollTo(0, location.state.scrollPosition);
        });
      }
    }
  }, [location.state]);

  const categories = [
    {
      id: 'TAP',
      name: 'Tap Water',
      icon: Droplets,
      description: 'Compare local tap water sources'
    },
    {
      id: 'STILL',
      name: 'Still Water',
      icon: GlassWater,
      description: 'Browse bottled still water brands'
    },
    {
      id: 'SPARKLING',
      name: 'Sparkling Water',
      icon: Sparkles,
      description: 'Explore sparkling water options'
    }
  ];

  const sortOptions = [
    { id: 'score-desc', label: 'Best Score First', icon: SortDesc },
    { id: 'score-asc', label: 'Lowest Score First', icon: SortAsc },
    { id: 'name-asc', label: 'Name (A-Z)', icon: SortAsc },
    { id: 'name-desc', label: 'Name (Z-A)', icon: SortDesc },
  ];

  const handleCategorySelect = (categoryId) => {
    log('Category selected:', categoryId);

    if (categoryId === selectedCategory) {
      setSelectedCategory(null);
    } else {
      setSelectedCategory(categoryId);
    }
  };

  const sortProducts = (products) => {
    return [...products].sort((a, b) => {
      const scoreA = calculateWaterScore(a).totalScore;
      const scoreB = calculateWaterScore(b).totalScore;

      switch (sortOption) {
        case 'score-desc':
          return scoreB - scoreA;
        case 'score-asc':
          return scoreA - scoreB;
        case 'name-asc':
          return a.name.localeCompare(b.name);
        case 'name-desc':
          return b.name.localeCompare(a.name);
        default:
          return 0;
      }
    });
  };

  const filteredProducts = sortProducts(
    selectedCategory && selectedCategory !== 'TAP'
      ? products.filter(product => product.type === selectedCategory)
      : products
  );

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="text-center">
          <div className="w-16 h-16 border-4 border-blue-500 border-t-transparent rounded-full animate-spin mx-auto mb-4"></div>
          <div className="text-gray-600">Loading products...</div>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-8">
      {/* Category Navigation */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {categories.map((category) => {
          const Icon = category.icon;
          const isSelected = selectedCategory === category.id;

          return (
            <button
              key={category.id}
              onClick={() => handleCategorySelect(category.id)}
              className={`p-6 rounded-xl border transition-all ${
                isSelected 
                  ? 'bg-blue-50 border-blue-200 shadow-sm' 
                  : 'bg-white hover:bg-gray-50 border-gray-200'
              }`}
            >
              <div className="flex items-center gap-3">
                <div className={`p-2 rounded-lg ${
                  isSelected ? 'bg-blue-100' : 'bg-gray-100'
                }`}>
                  <Icon className={`w-6 h-6 ${
                    isSelected ? 'text-blue-600' : 'text-gray-600'
                  }`} />
                </div>
                <div className="text-left">
                  <h3 className={`font-semibold ${
                    isSelected ? 'text-blue-900' : 'text-gray-900'
                  }`}>
                    {category.name}
                  </h3>
                  <p className="text-sm text-gray-500">{category.description}</p>
                </div>
              </div>
            </button>
          );
        })}
      </div>

      {selectedCategory === 'TAP' ? (
        <TapWaterDirectory />
      ) : (
        <>
          {/* Results Count and Sort Controls */}
          <div className="flex justify-between items-center">
            <h2 className="text-lg font-semibold text-gray-900">
              {selectedCategory 
                ? `${filteredProducts.length} ${selectedCategory.toLowerCase()} products`
                : `${filteredProducts.length} total products`}
            </h2>

            <SortMenu 
              sortOption={sortOption}
              setSortOption={setSortOption}
              sortOptions={sortOptions}
            />
          </div>

          {/* Product Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
            {filteredProducts.map((product) => {
              const { letterGrade } = calculateWaterScore(product);
              const gradeColorClass = getGradeColor(letterGrade);
              const contaminantCount = product.contaminants?.length || 0;

              return (
                <Link 
                  key={product.id}
                  to={`/product/${product.id}`}
                  className="block h-full"
                >
                  <div className="bg-white rounded-lg border p-4 hover:shadow-md transition-shadow h-full flex flex-col">
                    <div className="flex justify-between items-start gap-3 mb-2">
                      <div className="min-w-0 flex-1">
                        <h3 className="font-medium text-gray-900 break-words line-clamp-2">
                          {product.name}
                        </h3>
                        <p className="text-sm text-gray-500 break-words line-clamp-1">
                          {product.company}
                        </p>
                      </div>
                      <span className={`px-3 py-1 rounded-full text-sm font-semibold shrink-0 ${
                        gradeColorClass.replace('text-', 'bg-').replace('-600', '-100')
                      } ${gradeColorClass}`}>
                        {letterGrade}
                      </span>
                    </div>

                    <div className="text-sm text-gray-600 mt-auto pt-2 border-t">
                      {contaminantCount} detected contaminant{contaminantCount !== 1 ? 's' : ''}
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default Directory;