//File: src/pages/TapWater/components/TapWaterConcerns.jsx
import React from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { AlertCircle } from 'lucide-react';
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from '@/components/ui/accordion';
import ContaminantDetails from './ContaminantDetails';

const TapWaterConcerns = ({ municipal_water_contaminants }) => {
  const concernContaminants = municipal_water_contaminants?.filter(cont => {
    const level = parseFloat(cont.level);
    const guideline = parseFloat(cont.contaminants?.health_guideline);
    return !isNaN(level) && !isNaN(guideline) && level > guideline && level <= guideline * 2;
  }) || [];

  if (!concernContaminants.length) {
    return null;
  }

  return (
    <Card className="mb-6 bg-yellow-50 border-yellow-200">
      <CardHeader>
        <CardTitle className="text-lg flex items-center text-yellow-700">
          <AlertCircle className="h-5 w-5 mr-2" />
          Health Concerns
        </CardTitle>
      </CardHeader>
      <CardContent>
        <Accordion type="multiple" className="space-y-2">
          {concernContaminants.map((contaminant, index) => (
            <AccordionItem 
              key={`${contaminant.contaminants?.name}-${index}`} 
              value={`concern-${index}`} 
              className="border-yellow-200"
            >
              <AccordionTrigger className="text-yellow-700">
                {contaminant.contaminants?.name}
              </AccordionTrigger>
              <AccordionContent>
                <ContaminantDetails contaminant={contaminant} />
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      </CardContent>
    </Card>
  );
};

export default TapWaterConcerns;