//File: src/pages/TapWater/components/TapWaterHeader.jsx
import React from 'react';
import { Info } from 'lucide-react';

const TapWaterHeader = ({ municipality }) => {
  const totalContaminants = municipality.municipal_water_contaminants?.length || 0;
  const aboveGuidelineCount = municipality.municipal_water_contaminants?.filter(c => {
    const level = parseFloat(c.level);
    const guideline = parseFloat(c.contaminants?.health_guideline);
    return level > guideline;
  }).length || 0;

  return (
    <div className="mb-6 flex justify-between items-start">
      <div>
        <h1 className="text-2xl font-bold text-gray-900">{municipality.name}</h1>
        <p className="text-gray-600">{municipality.states?.name}</p>
      </div>
      <div className="text-right">
        <div className="text-gray-600">
          <div>{totalContaminants} Contaminants Detected</div>
          <div>{aboveGuidelineCount} Above Health Guidelines</div>
        </div>
      </div>
    </div>
  );
};

export default TapWaterHeader;