//File: src/pages/TapWater/components/TapWaterAdditionalInfo.jsx
import React from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { Info } from 'lucide-react';

const TapWaterAdditionalInfo = ({ municipality }) => {
  const totalContaminants = municipality.municipal_water_contaminants?.length || 0;
  const aboveGuidelineCount = municipality.municipal_water_contaminants?.filter(c => {
    const level = parseFloat(c.level);
    const guideline = parseFloat(c.contaminants?.health_guideline);
    return level > guideline;
  }).length || 0;

  return (
    <Card className="mb-6">
      <CardHeader>
        <CardTitle className="text-lg flex items-center">
          <Info className="h-5 w-5 mr-2" />
          Additional Information
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <div>
            <span className="font-medium">Total Contaminants Detected:</span>
            <span className="ml-2 text-gray-600">{totalContaminants}</span>
          </div>

          <div>
            <span className="font-medium">Contaminants Above Health Guidelines:</span>
            <span className="ml-2 text-gray-600">{aboveGuidelineCount}</span>
          </div>

          <div>
            <span className="font-medium">State:</span>
            <span className="ml-2 text-gray-600">{municipality.states?.name}</span>
          </div>

          {municipality.zip_code && (
            <div>
              <span className="font-medium">ZIP Code:</span>
              <span className="ml-2 text-gray-600">{municipality.zip_code}</span>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default TapWaterAdditionalInfo;