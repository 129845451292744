// src/pages/Home/components/IssuesSection.jsx
import React from 'react';
import { FlaskConical, AlertTriangle, Droplets, Shield } from 'lucide-react';

const IssuesSection = () => {
  const issues = [
    {
      icon: FlaskConical,
      iconColor: 'text-red-600',
      bgColor: 'bg-red-100',
      title: 'PFAS & Forever Chemicals',
      description: 'These synthetic chemicals never break down and accumulate in your body, linked to cancer, hormone disruption, and immune system damage.',
      stats: [
        'Found in 97% of blood samples',
        'Associated with multiple cancers',
        'Can pass from mother to child'
      ]
    },
    {
      icon: Shield,
      iconColor: 'text-purple-600',
      bgColor: 'bg-purple-100',
      title: 'Microplastics & Packaging',
      description: 'Microscopic plastic particles are prevalent in bottled water, with concerning implications for long-term health effects.',
      stats: [
        'Present in 93% of bottled water',
        'Can carry harmful chemicals',
        'Accumulate in body tissues'
      ]
    },
    {
      icon: Droplets,
      iconColor: 'text-blue-600',
      bgColor: 'bg-blue-100',
      title: 'Chemical Contaminants',
      description: 'From industrial pollutants to agricultural runoff, numerous harmful chemicals can make their way into drinking water.',
      stats: [
        '600+ regulated contaminants',
        'Many linked to cancer risk',
        'Varies by water source'
      ]
    }
  ];

  return (
    <section className="py-20 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4">
        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold text-gray-900">Understanding Water Safety Risks</h2>
          <p className="mt-4 text-xl text-gray-600 max-w-3xl mx-auto">
            Modern water supplies face multiple contamination challenges. Learn what to look out for to protect your health.
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8">
          {issues.map((issue, index) => (
            <div key={index} className="bg-white rounded-xl p-8 shadow-sm border">
              <div className={`w-12 h-12 ${issue.bgColor} rounded-lg flex items-center justify-center mb-4`}>
                <issue.icon className={`h-6 w-6 ${issue.iconColor}`} />
              </div>
              <h3 className="text-xl font-semibold text-gray-900 mb-3">{issue.title}</h3>
              <p className="text-gray-600 mb-6">
                {issue.description}
              </p>
              <ul className="space-y-3">
                {issue.stats.map((stat, statIndex) => (
                  <li key={statIndex} className="flex items-center text-sm text-gray-500">
                    <AlertTriangle className="h-4 w-4 text-amber-500 mr-2 shrink-0" />
                    {stat}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default IssuesSection;