//File: src/pages/TapWater/components/ContaminantDetails.jsx
import React from 'react';

const ContaminantDetails = ({ contaminant }) => {
  const getTimesOverGuideline = () => {
    if (!contaminant.contaminants?.health_guideline) return 0;
    const level = parseFloat(contaminant.level);
    const guideline = parseFloat(contaminant.contaminants.health_guideline);
    if (isNaN(level) || isNaN(guideline) || guideline === 0) return 0;
    return (level / guideline).toFixed(1);
  };

  const getTimesOverLegalLimit = () => {
    if (!contaminant.contaminants?.legal_limit) return null;
    const level = parseFloat(contaminant.level);
    const limit = parseFloat(contaminant.contaminants.legal_limit);
    if (isNaN(level) || isNaN(limit) || limit === 0) return null;
    return (level / limit).toFixed(2);
  };

  const timesOverGuideline = getTimesOverGuideline();
  const timesOverLegal = getTimesOverLegalLimit();

  return (
    <div className="p-6 rounded-lg bg-gray-50 space-y-6">
      {/* Current Level Section */}
      <div>
        <div className="text-sm font-medium text-gray-500 mb-1">Current Level</div>
        <div className="text-lg font-semibold">
          {contaminant.level} {contaminant.unit}
        </div>
      </div>

      {/* Health Guideline Section */}
      {contaminant.contaminants?.health_guideline && (
        <div>
          <div className="text-sm font-medium text-gray-500 mb-1">Health Guideline</div>
          <div className="text-lg font-semibold">
            {contaminant.contaminants.health_guideline} {contaminant.unit}
          </div>
          <div className="mt-4 p-4 bg-white rounded-lg border border-gray-200">
            <div className="text-4xl font-bold text-center text-red-600">
              {timesOverGuideline}x
            </div>
            <div className="text-sm text-center text-gray-600 mt-1">
              over health guideline
            </div>
          </div>
        </div>
      )}

      {/* Legal Limit Section */}
      {contaminant.contaminants?.legal_limit && (
        <div>
          <div className="text-sm font-medium text-gray-500 mb-1">Legal Limit</div>
          <div className="text-lg font-semibold">
            {contaminant.contaminants.legal_limit} {contaminant.unit}
          </div>
          {timesOverLegal && (
            <div className="mt-2 inline-flex items-center px-3 py-1 rounded-full bg-gray-100">
              <span className="text-sm font-medium">
                {timesOverLegal}x of legal limit
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ContaminantDetails;