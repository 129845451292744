//File: src/pages/TapWater/components/TapWaterWarnings.jsx
import React from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { AlertTriangle } from 'lucide-react';
import { 
  Accordion, 
  AccordionItem, 
  AccordionTrigger, 
  AccordionContent 
} from '@/components/ui/accordion';
import ContaminantDetails from './ContaminantDetails';

const TapWaterWarnings = ({ municipal_water_contaminants }) => {
  const warningContaminants = municipal_water_contaminants?.filter(cont => {
    const level = parseFloat(cont.level);
    const guideline = parseFloat(cont.contaminants?.health_guideline);
    return !isNaN(level) && !isNaN(guideline) && level > guideline * 2;
  }) || [];

  if (!warningContaminants.length) {
    return null;
  }

  return (
    <Card className="mb-6 bg-red-50 border-red-200">
      <CardHeader>
        <CardTitle className="text-lg flex items-center text-red-700">
          <AlertTriangle className="h-5 w-5 mr-2" />
          Health Warnings
        </CardTitle>
      </CardHeader>
      <CardContent>
        <Accordion type="multiple" className="space-y-2">
          {warningContaminants.map((contaminant, index) => (
            <AccordionItem 
              key={`${contaminant.contaminants?.name}-${index}`} 
              value={`contaminant-${index}`} 
              className="border-red-200"
            >
              <AccordionTrigger className="hover:no-underline w-full">
                <div className="flex items-center justify-between flex-1 pr-2">
                  <div className="text-red-700 font-medium">
                    {contaminant.contaminants?.name}
                  </div>
                  <div className="text-sm text-red-600">
                    {((parseFloat(contaminant.level) / parseFloat(contaminant.contaminants?.health_guideline)) || 0).toFixed(1)}x over health guideline
                  </div>
                </div>
              </AccordionTrigger>
              <AccordionContent>
                <div className="pt-2 pb-1">
                  <ContaminantDetails contaminant={contaminant} />
                </div>
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      </CardContent>
    </Card>
  );
};

export default TapWaterWarnings;