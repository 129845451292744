// File: src/pages/TapWater/TapWaterDetail.jsx
import React, { useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { useTapWater } from '../../contexts/TapWaterContext';
import TapWaterHeader from './components/TapWaterHeader';
import TapWaterWarnings from './components/TapWaterWarnings';
import TapWaterConcerns from './components/TapWaterConcerns';
import TapWaterSafeLevels from './components/TapWaterSafeLevels';
import TapWaterAdditionalInfo from './components/TapWaterAdditionalInfo';

const DEBUG = true;
const log = (...args) => DEBUG && console.log('[TapWaterDetail]', ...args);

const TapWaterDetail = () => {
  const { municipalityId } = useParams();
  const navigate = useNavigate();
  const { getMunicipality, loading, navigationState } = useTapWater();

  const municipality = getMunicipality(municipalityId);

  // Scroll to top on mount
  useEffect(() => {
    log('Component mounted');
    window.scrollTo(0, 0);
    return () => {
      log('Component unmounting');
    };
  }, []);

  const handleBack = useCallback(() => {
    // Navigate back to directory
    navigate('/directory', {
      state: {
        showTapWater: true,
        scrollPosition: location.state?.scrollPosition || 0,
        selectedCategory: 'TAP',
        openState: location.state?.openState
      }
    });
  }, [navigate, location.state]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-center">
          <div className="w-16 h-16 border-4 border-blue-500 border-t-transparent rounded-full animate-spin mx-auto mb-4"></div>
          <div className="text-gray-600">Loading water quality data...</div>
        </div>
      </div>
    );
  }

  if (!municipality) {
    return (
      <div className="max-w-3xl mx-auto p-6">
        <div className="text-center py-12">
          <h2 className="text-2xl font-semibold text-gray-900 mb-2">Municipality Not Found</h2>
          <p className="text-gray-600 mb-6">The water system you're looking for doesn't exist.</p>
          <button
            onClick={handleBack}
            className="text-blue-600 hover:text-blue-800 font-medium"
          >
            Return to Directory
          </button>
        </div>
      </div>
    );
  }

  // Transform the data structure to match what the components expect
  const transformedMunicipality = {
    ...municipality,
    municipal_water_contaminants: municipality.contaminants.map(contaminant => ({
      level: contaminant.level,
      unit: contaminant.unit,
      contaminants: {
        name: contaminant.name,
        legal_limit: contaminant.legal_limit,
        health_guideline: contaminant.health_guideline,
        description: contaminant.description,
        risks: contaminant.risks
      }
    })),
    states: {
      name: municipality.state
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 pb-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="py-6">
          <button
            onClick={handleBack}
            className="flex items-center text-gray-600 hover:text-gray-900"
          >
            <ArrowLeft className="h-4 w-4 mr-2" />
            Back to Directory
          </button>
        </div>

        <div className="max-w-3xl mx-auto">
          <TapWaterHeader municipality={transformedMunicipality} />
          <TapWaterWarnings 
            municipal_water_contaminants={transformedMunicipality.municipal_water_contaminants} 
          />
          <TapWaterConcerns 
            municipal_water_contaminants={transformedMunicipality.municipal_water_contaminants} 
          />
          <TapWaterSafeLevels 
            municipal_water_contaminants={transformedMunicipality.municipal_water_contaminants} 
          />
          <TapWaterAdditionalInfo municipality={transformedMunicipality} />
        </div>
      </div>
    </div>
  );
};

export default TapWaterDetail;