// File: src/utils/waterScoring.js

// Contaminant severity levels and their multipliers
const CONTAMINANT_SEVERITY = {
  CRITICAL: {
    multiplier: 2.0,
    contaminants: [
      'Lead',
      'Mercury',
      'Total Mercury',
      'Arsenic',
      'Cadmium',
      'Uranium',
      'Radium 226',
      'Radium 228',
      'Radium combined (226 and 228)',
      'E. Coli',
      'Chromium-6',
      'Benzene'
    ]
  },
  HIGH: {
    multiplier: 1.5,
    contaminants: [
      'Antimony',
      'Beryllium',
      'Trichloroethylene',
      'Tetrachloroethylene',
      'Thallium',
      'Bromate',
      'Nitrate',
      'Nitrite'
    ]
  },
  STANDARD: {
    multiplier: 1.0
  }
};

// Penalty for total number of contaminants present
const TOTAL_CONTAMINANTS_PENALTY = {
  LOW: { threshold: 3, penalty: -5 },      // 3-5 contaminants
  MODERATE: { threshold: 6, penalty: -10 }, // 6-8 contaminants
  HIGH: { threshold: 9, penalty: -15 },     // 9+ contaminants
};

// Major penalties
const MAJOR_CONTAMINANT_PENALTIES = {
  PFAS: {
    DETECTED: -25,    // Most severe penalty
    UNTESTED: -10,
    NONE: 0
  }
};

// Packaging penalties
const PACKAGING_PENALTIES = {
  'PLASTIC': -25,     // Second most severe penalty
  'ALUMINUM': -8,
  'CAN': -8,
  'CARDBOARD': -5,
  'GLASS': 0
};

// Source impacts
const SOURCE_PENALTIES = {
  'MUNICIPAL': -8,
  'TAP': -8,
  'UNKNOWN': 0
};

const SOURCE_BONUSES = {
  'SPRING': 5,
  'ARTESIAN': 5,
  'GLACIER': 5,
  'AQUIFER': 5,
  'WELL': 3
};

// pH ranges
const PH_RANGES = {
  OPTIMAL: { min: 6.5, max: 8.5, penalty: 0 },
  ACCEPTABLE: { min: 6.0, max: 9.0, penalty: -3 },
  SEVERE: { penalty: -8 }
};

const NO_LAB_REPORT_PENALTY = -15;

// Helper function to calculate contaminant-specific penalties
const calculateContaminantPenalty = (percentOverGuideline, contaminantName) => {
  // Determine base penalty based on how much it exceeds
  let basePenalty;
  if (percentOverGuideline <= 125) {
    basePenalty = -5;  // Barely over
  } else if (percentOverGuideline <= 150) {
    basePenalty = -10; // Moderately over
  } else if (percentOverGuideline <= 200) {
    basePenalty = -15; // Significantly over
  } else if (percentOverGuideline <= 500) {
    basePenalty = -20; // Severely over
  } else {
    basePenalty = -25; // Extremely over
  }

  // Get severity multiplier
  let severityMultiplier = 1.0;
  if (CONTAMINANT_SEVERITY.CRITICAL.contaminants.includes(contaminantName)) {
    severityMultiplier = CONTAMINANT_SEVERITY.CRITICAL.multiplier;
  } else if (CONTAMINANT_SEVERITY.HIGH.contaminants.includes(contaminantName)) {
    severityMultiplier = CONTAMINANT_SEVERITY.HIGH.multiplier;
  }

  // Apply multiplier and round to whole number
  return Math.round(basePenalty * severityMultiplier);
};

// Main scoring function
export function calculateWaterScore(waterProduct) {
  let score = 100;
  let penalties = [];
  let warnings = [];

  // Process PFAS (highest priority)
  if (waterProduct.pfas === true || waterProduct.pfas === 'YES') {
    score += MAJOR_CONTAMINANT_PENALTIES.PFAS.DETECTED;
    warnings.push('Forever Chemicals (PFAS) Detected');
    penalties.push(`PFAS detected: ${MAJOR_CONTAMINANT_PENALTIES.PFAS.DETECTED}`);
  } else if (waterProduct.pfas === null || waterProduct.pfas === undefined) {
    score += MAJOR_CONTAMINANT_PENALTIES.PFAS.UNTESTED;
    penalties.push(`PFAS untested: ${MAJOR_CONTAMINANT_PENALTIES.PFAS.UNTESTED}`);
  }

  // Process contaminants
  if (waterProduct.contaminants?.length > 0) {
    const processedNitrates = new Set();
    let totalContaminantsPresent = 0;

    waterProduct.contaminants.forEach(contaminant => {
      // Skip if no amount or amount is 0
      if (!contaminant.amount || contaminant.amount === 0) return;

      // Skip PFAS contaminants since we handle them separately
      if (contaminant.name.toLowerCase().includes('pfas')) return;

      // Handle nitrate/nitrite deduplication
      if (contaminant.name === 'Nitrate and Nitrite (Combined)') {
        const hasIndividual = waterProduct.contaminants.some(c => 
          c.name === 'Nitrate' || c.name === 'Nitrite'
        );
        if (hasIndividual) return;
      }

      if (contaminant.name === 'Nitrate' || contaminant.name === 'Nitrite') {
        if (processedNitrates.has('nitrate')) return;
        processedNitrates.add('nitrate');
      }

      // Count total contaminants
      totalContaminantsPresent++;

      // Process health guideline violations
      if (contaminant.health_guideline && contaminant.amount) {
        const percentOfHealthGuideline = (contaminant.amount / contaminant.health_guideline) * 100;

        if (percentOfHealthGuideline > 100) {
          const penalty = calculateContaminantPenalty(percentOfHealthGuideline, contaminant.name);
          score += penalty;
          penalties.push(
            `${contaminant.name}: ${penalty} (${Math.round(percentOfHealthGuideline)}% of guideline, ${
              CONTAMINANT_SEVERITY.CRITICAL.contaminants.includes(contaminant.name) 
                ? 'critical severity' 
                : CONTAMINANT_SEVERITY.HIGH.contaminants.includes(contaminant.name)
                  ? 'high severity'
                  : 'standard severity'
            })`
          );
          warnings.push(`${contaminant.name} exceeds health guidelines`);
        }
      }
    });

    // Apply penalty for total number of contaminants
    if (totalContaminantsPresent >= TOTAL_CONTAMINANTS_PENALTY.HIGH.threshold) {
      score += TOTAL_CONTAMINANTS_PENALTY.HIGH.penalty;
      penalties.push(`High number of contaminants (${totalContaminantsPresent}): ${TOTAL_CONTAMINANTS_PENALTY.HIGH.penalty}`);
    } else if (totalContaminantsPresent >= TOTAL_CONTAMINANTS_PENALTY.MODERATE.threshold) {
      score += TOTAL_CONTAMINANTS_PENALTY.MODERATE.penalty;
      penalties.push(`Moderate number of contaminants (${totalContaminantsPresent}): ${TOTAL_CONTAMINANTS_PENALTY.MODERATE.penalty}`);
    } else if (totalContaminantsPresent >= TOTAL_CONTAMINANTS_PENALTY.LOW.threshold) {
      score += TOTAL_CONTAMINANTS_PENALTY.LOW.penalty;
      penalties.push(`Multiple contaminants present (${totalContaminantsPresent}): ${TOTAL_CONTAMINANTS_PENALTY.LOW.penalty}`);
    }
  }

  // Process packaging (second highest priority)
  if (waterProduct.packaging) {
    const packagingPenalty = PACKAGING_PENALTIES[waterProduct.packaging.toUpperCase()] || 0;
    if (packagingPenalty < 0) {
      score += packagingPenalty;
      penalties.push(`Packaging (${waterProduct.packaging}): ${packagingPenalty}`);
    }
  }

  // Process source scoring
  const sourceType = waterProduct.source?.toUpperCase();
  if (sourceType) {
    if (SOURCE_PENALTIES[sourceType]) {
      score += SOURCE_PENALTIES[sourceType];
      penalties.push(`Source (${sourceType}): ${SOURCE_PENALTIES[sourceType]}`);
    }
    if (SOURCE_BONUSES[sourceType]) {
      score += SOURCE_BONUSES[sourceType];
      penalties.push(`Source bonus (${sourceType}): +${SOURCE_BONUSES[sourceType]}`);
    }
  } else {
    score += SOURCE_PENALTIES.UNKNOWN;
    penalties.push(`Unknown source: ${SOURCE_PENALTIES.UNKNOWN}`);
  }

  // pH penalties
  if (waterProduct.ph) {
    const ph = waterProduct.ph;
    let phPenalty = 0;

    if (ph < PH_RANGES.ACCEPTABLE.min || ph > PH_RANGES.ACCEPTABLE.max) {
      phPenalty = PH_RANGES.SEVERE.penalty;
      warnings.push('pH level severely out of range');
    } else if (ph < PH_RANGES.OPTIMAL.min || ph > PH_RANGES.OPTIMAL.max) {
      phPenalty = PH_RANGES.ACCEPTABLE.penalty;
    }

    if (phPenalty < 0) {
      score += phPenalty;
      penalties.push(`pH level (${ph}): ${phPenalty}`);
    }
  }

  // Ensure score stays within bounds
  score = Math.max(0, Math.min(105, score));

  return {
    totalScore: Math.round(score),
    letterGrade: scoreToGrade(score),
    penalties,
    warnings
  };
}

export function scoreToGrade(score) {
  if (score >= 97) return 'A+';
  if (score >= 93) return 'A';
  if (score >= 90) return 'A-';
  if (score >= 87) return 'B+';
  if (score >= 83) return 'B';
  if (score >= 80) return 'B-';
  if (score >= 77) return 'C+';
  if (score >= 73) return 'C';
  if (score >= 70) return 'C-';
  if (score >= 67) return 'D+';
  if (score >= 63) return 'D';
  if (score >= 60) return 'D-';
  return 'F';
}

export function getGradeColor(grade) {
  const baseGrade = grade.charAt(0);
  const colors = {
    'A': 'text-green-600',
    'B': 'text-blue-600',
    'C': 'text-yellow-600',
    'D': 'text-orange-600',
    'F': 'text-red-600'
  };
  return colors[baseGrade] || 'text-gray-600';
}

// Re-export all constants that other modules might need
export {
  CONTAMINANT_SEVERITY,
  TOTAL_CONTAMINANTS_PENALTY,
  MAJOR_CONTAMINANT_PENALTIES,
  PACKAGING_PENALTIES,
  SOURCE_PENALTIES,
  SOURCE_BONUSES,
  PH_RANGES
};